html {
    scroll-behavior: smooth;
  }
  
  *{
      margin: 0;
      padding: 0;
      font-weight: 300;
  }
  
  body{
      overflow-x: hidden;
  }
  