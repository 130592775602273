.fb-ic, .tw-ic , .ins-ic{
    font-size: 20px;
  }
  
  .font-weight-bold {
    font-size: 14px;
  }
  
  #footertext, #commontext {
    font-size: 18px;
    line-height: 2;
  }
  