$primary-color: #272727;
$text-color: #fff;
$primary: #b62131;
$warning: red;
$font-weight: (
    "normal": 400,
    "medium": 500,
    "bold": 700
);
$font-type: 'Montserrat';
$padding: 15px;
$borders: 15px;
$desktop: 840px;