
.jumbotron {
  position: relative;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 0px;
  border-radius: 0px;
  background-color: $text-color !important;
  &-background {
    position: absolute;
    right: 40%;
    margin: auto;
    overflow: hidden;
    height: 385px;
    width: 3400px;
    z-index: 1;
    border-radius: 50px;
  }
  .container {
    position: relative;
    z-index: 2;
  }
  &-title {
    font-size: 32px !important;
    margin-top: 10%;
    line-height: 1.5;
    text-align: left;
  }
  &-description {
    font-size: 16px;
    word-spacing: 2px;
    font-weight: 500;
    margin-bottom: 4%;
  }
}

.hero-image-container {
  height: 460px;
  width: 396px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &::before {
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    border-radius: 500px;
    z-index: 1;
  }

  &::after {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
    border-radius: 500px;
    z-index: 2;
  }

  img {
    max-height: 100%;
    position: relative;
    z-index: 3;
  }
}

header {
    overflow: hidden;
    text-align: center;
  }
  
  header a, header label {
    display: block;
    padding: 20px;
    color: $primary-color;
    text-decoration: none;
    line-height: 20px;
  }
  
  #herotext{
    padding-top: 60%;
    padding-right: 5%;
    font-size: 25px;
  }

  a {
    color: $primary-color !important;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
  }

  .socialhover {
    padding: 5px 15px;
    width: 100%;
    color: $text-color;
  }

  a:hover {
    text-decoration: none !important;
    color: $primary-color !important;
  }

  a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: $primary;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  
  a:hover:after { 
    width: 50%; 
    left: 23%; 
  }
  
  header label:after {
    content: "\2261";
    font-size: 3.8em;
  }
  
h1, h2, h2 {
text-align: left;
}

  nav {
    float: right;
    display: block;
    max-height: 0;
    width: 100%;
    -webkit-transition: max-height 0.3s; 
       -moz-transition: max-height 0.3s;
         -o-transition: max-height 0.3s;
            transition: max-height 0.3s;
  }

  nav ul {
    margin: 0;
    padding: 0;
     padding-bottom: 10px;
  }
    
  nav li {
    display: block;
    text-align: center;
    font-size: 14px;
  }

  nav a {
    padding: 10px;
    width: 100%;
  }
  
  #nav { 
    display: none; 
  }
  
  #nav:checked ~ nav {
    max-height: 200px; 
  }
  
  @media only screen and (min-width: 700px) {
    
    header label { display: none; }
    
    nav {
      width: 100%;
      max-height: none;
    }
    
    nav ul {
      padding: 0;
      padding-right: 10px;
    }
    
    nav li {
      display: inline-block;
      text-align: left;
    }
    
    header nav a {
        display: inline-block;
        padding: 29px 36px 10px 36px;
        width: auto;
        font-size: 15px;
    }

    #fixed-social {
      visibility: hidden;
    }
  }

@media only screen and (min-width: 600px) {
  #fixed-social {
    visibility: hidden;
  }
  }

@media only screen and (min-width: 1200px) {
  #fixed-social {
    visibility: visible;
  }
}
  

#fixed-social {
  position: fixed;
  top: 40%;
}

#fixed-social a {
  color: $text-color;
  display: block;
  height: 58px;
  position: relative;
  text-align: center;
  line-height: 29px;
  width: 87px;
  margin-bottom: 5px;
  z-index: 2;
  font-size: 18px;
  border-radius: 1%;
}

#fixed-social a:hover>span{
 visibility: visible;
 left: 90px;
 opacity: 1;


} 
#fixed-social a span {
 line-height: 40px;
   left: 60px;
   position: absolute;
   text-align: center;
   width: 200px;
   visibility: hidden;
   transition-duration: 0.5s;
   z-index: 1;
   opacity: 0;
}

.fixed-facebook, .fixed-facebook span, .fixed-instagram, .fixed-instagram span, .fixed-mail, .fixed-mail span{
  background-color: $primary;
}

.fb-ic ,.tw-ic ,.ins-ic ,.ins-ic{
  color: $primary !important;
}

.fixed-instagram, .fixed-mail, .fixed-facebook {
  color: $text-color !important;
}

hr{
  border: none;
  margin-top: 1%;
  height: 2px;
  margin-left:25% !important; margin-right:25% !important;
  color: $primary-color; 
  background-color: $primary-color; 
}

.Logo {
  text-align: center;
  margin-bottom: 2%;
  text-transform: uppercase;
  font-size: 26px;
}

.sectionheading {
  text-align: left;
  margin-bottom: 2%;
  font-size: 50px;
}

#aboutcommontext {
 font-size: 16px;
 line-height: 2;
 text-align: left;
}

.col-sm-7, .col-sm-5 {
  height: 0%;
  margin-bottom: 4% !important;
  margin-top: 4% !important;
}

.bold {
  font-weight: bolder;
}


.recipes {
  background-color: pink;
}

.hero {
  margin-left: 10%;
  margin-right: 10%;
}

.row1 {
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-wrap: wrap; 
  flex-wrap: wrap;
  padding: 0 4px;
  margin-bottom: 2%;
}

img {
  width: 100%;
}

.column1 {
  -ms-flex: 25%; 
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column1 img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}


@media screen and (max-width: 800px) {
  .column1 {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}


@media screen and (max-width: 600px) {
  .column1 {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }

  #fixed-social {
    visibility: hidden;
  }
}

  .cta {
  position: relative;
  margin: auto;
  margin-bottom: 4%;
  text-decoration: none;
  padding: 19px 22px;
  transition: all .2s ease;

    &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 28px;
    background-color: rgba($primary,.5);
    width: 56px;
    height: 56px;
    transition: all .3s ease; 
    }

  span{
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    letter-spacing: .25em;
    text-transform: uppercase;
    vertical-align: middle;}

  svg{
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: $primary-color;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all .3s ease;}
    
  &:hover{
    &:before{
      width: 100%;
      background: rgba($primary,1);}

    svg{
      transform: translateX(0);}
    
  &:active{
    transform: scale(.96);}
  }
}

.wrapper {
  position: relative; 
  
}

.image {
  position:relative;
  display:inline-block;
}

img {
  transition: .5s ease-in-out all;
}

.text {
  font-weight:600;
  font-size:1.25em;
  position: absolute;
  top:0; bottom:0; left:0; right:0;
  margin-top: 15%;
  display: table;
  vertical-align: middle;
  text-align: left;
  transition: .5s ease-in-out all;
  opacity:0;
  text-align: left;
  width:100%;
  padding: 5%;
}

.image:hover img {
  opacity:.3;
}
.image:hover .text{
  opacity:1;
}

.back-img {
  display: block;
  background-image: url("https://cdn.hipwallpaper.com/i/33/77/uUgELz.jpg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=940");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  width: 100%;
  margin-bottom: 4%;
  background-position: center;
}


.styles_scroll-to-top__2A70v {
  background-color: transparent !important;
  border-radius: 50% !important;
  box-shadow: none !important;
}

.carousel-root {
  width: 100% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background:$text-color !important;
  color: $primary-color;
  height: 100%;
}

.myCarousel {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 240px;
}

.carousel .slide img {
  width: 50% !important;
}

.carousel .carousel-status {
  display: none !important;
}



@media only screen and (max-width: 1500px) {
  .carousel .slide img {
    width: 100% !important;
  }
  
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 1% !important;
}

.myCarousel h3 {
  color: $primary-color;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: $primary-color;
  font-size: 15px;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  font-size: 26px;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  font-size: 26px;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background:$primary-color !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: $primary-color !important;
  height: 100px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 100% !important;
    height: auto;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
}

input {
  display: none;
}

label {
  cursor: pointer;
  border-radius: 4px;
  background: $text-color;
  -webkit-transition: background-color 0.1s, color 0.1s;
}

label:hover {
  // background: $primary;
  // color: #000;
}

.test {
  -webkit-transition: height .3s ease;
  height: 0;
  overflow: hidden;
  width: 100%;
  background: red;
  margin-top: 10px;
}

input:checked + .test {
  height: auto;
}

.accordion__button {
  background-color: $text-color !important;
  cursor: pointer;
  padding: 26px;
  width: 98% !important;
  text-align: left;
  border: none;
  margin: 15px;
  border-radius: 50px;
}

.accordion__button:hover {
  background-color: $primary !important;
  color: $text-color !important;
}

.accordion {
  border: transparent !important;
  border-radius: 0px !important;
  margin-bottom: 4% !important;
}

.accordion__heading {
  font-size: 18px;
}

.accordion__panel {
  background-color: $text-color;
  border-radius: 50px;
}

.recipieimage{
  width: 100%;
}

.recipetext {
  font-size: 16px;
  padding-left: 6% !important;
  text-align: left !important;
}

.warning {
  color:$warning !important;
}

.heroimage {
  margin: -3%;
  margin-top: 4%;
  margin-bottom: 4%;
}

@media screen and (max-width: 1000px) {
  .heroimage {
   width: 0%;
  }
}

@media screen and (min-width: 992px) {
  main {
    height: 0;
    visibility: collapse;
}
}